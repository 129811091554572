app.factory('InvitationsData', ['Firebase', '$q', function (Firebase, $q) {

  var authObj = Firebase.getAuth();

  return {
    get: function (code) {
      return $q(function (resolve, reject) {
        authObj.$signInAnonymously().then(function (authData) {
          Firebase.obj('/invitations/' + code).$loaded().then(function (invitation) {
            if (invitation.$value === null) {
              reject(new Error('Invitation Code Not Valid.'));
            } else {
              resolve(invitation);
            }
          }, function (error) {
            reject(error);
          });
        });
      });
    }
  };

}]);
