app.factory('Meta', [function () {

  var title = 'Ask Kodiak - Commercial Insurance Search',
      description = 'Kodiak is a commercial insurance search engine that keeps agents and brokers up-to-date on carrier appetite',
      keywords = 'P&C, Commercial Insurance, Appetite Search, Commercial Lines Eligibility, Agents, Brokers, Carriers';

  return {
    title: function () {
      return title;
    },
    setTitle: function (newTitle) {
      title = newTitle;
    },
    description: function () {
      return description;
    },
    setDescription: function (newDescription) {
      description = newDescription;
    },
    keywords: function () {
      return keywords;
    },
    setKeywords: function (newKeywords) {
      keywords = newKeywords;
    }
  };

}]);
