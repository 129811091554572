app.factory('Go', ['$window', '$location', function (
  $window,
  $location
) {

  return {
    back: function () {
      //this is the only page they've visited, back isn't going to work.
      if ($window.history.length === 1) {
        $location.path('/');
      }
      $window.history.back();
    }
  };

}]);
