app.controller('VideoController', ['$scope', '$window', '$routeParams', 'Meta', 'Analytics', 'Viewport', 'ASKKODIAK_APP_URL', function (
  $scope,
  $window,
  $routeParams,
  Meta,
  Analytics,
  Viewport,
  ASKKODIAK_APP_URL
) {

  $scope.askkodiakAppURL = ASKKODIAK_APP_URL;

  $scope.mediaId = $routeParams.mediaId;

  Meta.setTitle('Ask Kodiak Video');

  Viewport.initScroll(false, $('section.releases'));

  /* ---- WISTIA STUFF ------ */
  $scope.play = function (video) {
    Analytics.track('video-play', {
      videoName: video._mediaData.name,
      videoId: video._mediaData.hashedId,
      videoPlayerType: video.playerType
    });
  };
  // Get a handle on embedded wistia videos so that we can track that they're playing.
  $window._wq = $window._wq || [];

  $window._wq.push({ '_all': function (video) {
    video.bind('play', function () {
      $scope.play(this);
    });
  }});
  /* ---- END WISTIA STUFF ------ */

}]);
