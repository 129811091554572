app.factory('IP', ['$http', function ($http) {

  //http://ip-api.com/docs/api:json

  var ipData,
      url = 'https://api.ipstack.com/check?access_key=b5bd531b6dedee9397d1ac925fe81ed1';

  function lookup() {
    if (ipData) {
      return ipData;
    }
    ipData = $http.get(url);
    return ipData;
  }

  return {
    lookup: function () {
      return lookup();
    }
  };

}]);
