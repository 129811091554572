app.controller('CarriersController', ['$scope', '$window', 'Meta', 'Analytics', 'Viewport', function (
  $scope,
  $window,
  Meta,
  Analytics,
  Viewport
) {

  Meta.setTitle('Ask Kodiak for Carriers');

  Viewport.initScroll(false, $('section.carriers'));

  /* ---- WISTIA STUFF ------ */
  $scope.play = function (video) {
    Analytics.track('video-play', {
      videoName: video._mediaData.name,
      videoId: video._mediaData.hashedId,
      videoPlayerType: video.playerType
    });
  };
  // Get a handle on embedded wistia videos so that we can track that they're playing.
  $window._wq = $window._wq || [];

  $window._wq.push({ '_all': function (video) {
    video.bind('play', function () {
      $scope.play(this);
    });
  }});
  /* ---- END WISTIA STUFF ------ */

}]);
