app.factory('MD5', ['$window', function (
  $window
) {

  var md5 = $window.md5;

  return {
    hash: function (string) {
      return md5(string);
    }
  };

}]);
