app.factory('Session', ['$log', '$location', 'IP', 'Analytics', function (
  $log,
  $location,
  IP,
  Analytics) {

  var start,
      end,
      duration,
      views = [],
      referrer = document.referrer,
      params = $location.search(),
      campaign = params.c || null,
      ipLookup = IP.lookup(),
      ipData;

  return {
    start: function () {
      start = new Date();
      ipLookup.then(function (response) {
        ipData = response.data;
      }, function (error) {
        $log.error(error);
      });
    },
    view: function (page) {
      views.push(page);
    },
    end: function (newTitle) {
      end = new Date();
      //determine duration in milliseconds
      duration = end.getTime() - start.getTime();

      Analytics.track('session', {
        start: start,
        end: end,
        duration: duration,
        views: views,
        referrer: referrer,
        campaign: campaign,
        ipData: ipData
      });
    }
  };

}]);
