app.controller('LegalController', ['$scope', 'Viewport', 'Meta', 'ADDRESSES', function (
  $scope,
  Viewport,
  Meta,
  ADDRESSES
) {

  Meta.setTitle('AskKodiak Fine Print');

  Viewport.initScroll(false, $('section.legal'));

  $scope.addresses = ADDRESSES;

}]);
