app.factory('Version', ['VERSION', function (VERSION) {

  var version;

  function parseVersion(obj) {
    // takes raw version information and parses it into
    // major/minor/patch components

    var numbers = obj.version.split('.'),
        label = obj.version;

    obj.version = {
      label: label,
      major: numbers[0],
      minor: numbers[1],
      patch: numbers[2]
    };

    return obj;
  }

  version = parseVersion(VERSION);

  return {
    get: function () {
      return version;
    }
  };

}]);
