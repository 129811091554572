app.factory('Mail', ['MAIL', 'Tasks', function (
  MAIL,
  Tasks
) {

  return {
    send: function (message) {
      message.from_email = MAIL.from.address;
      message.from_name =  MAIL.from.name;
      message.headers = {
        'Reply-To': MAIL.from.address
      };
      return Tasks.mailer(message);
    }
  };

}]);
