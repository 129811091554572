app
  .run(['$log', 'Analytics', 'Session', function ($log, Analytics, Session) {
    Session.start();
    Analytics.track('app loaded');
  }])
  .run(['$rootScope', 'Analytics', 'Session', function ($rootScope, Analytics, Session) {
    $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {

      var previousView = '',
          currentView = current.loadedTemplateUrl;

      if (typeof previous !== 'undefined') {
        previousView = previous.loadedTemplateUrl;
      }

      Session.view({
        current: currentView,
        previous: previousView
      });

      Analytics.track('page view', {
        current: currentView,
        previous: previousView
      });

    });
  }])
  .run(['$rootScope', '$location', '$log', function ($rootScope, $location, $log) {
    var params = $location.search(),
        inviteCode = params.inviteCode || null;

    $log.info(inviteCode);

    $rootScope.inviteCode = inviteCode;
  }])
  .run(['$window', 'Session', function ($window, Session) {
    $window.addEventListener('beforeunload', function () {
      Session.end();
    });
  }]);
