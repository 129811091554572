app.controller('ReservedDomainModalController', ['$scope', '$uibModalInstance', 'Analytics', 'reservation', function (
  $scope,
  $uibModalInstance,
  Analytics,
  reservation
) {

  Analytics.track('reserved domain modal opened');

  $scope.reservation = reservation;

  $scope.close = function (response) {
    $uibModalInstance.close(response);
  };

}]);
