app.controller('WholesalersController', ['$scope', '$uibModal', '$log', 'Meta', 'Viewport', function (
  $scope,
  $uibModal,
  $log,
  Meta,
  Viewport
) {

  Meta.setTitle('Ask Kodiak for Wholesalers');

  Viewport.initScroll(false, $('section.wholesalers'));

}]);
