app.controller('HomeController', ['$scope', '$location', 'Analytics', 'Viewport', function (
  $scope,
  $location,
  Analytics,
  Viewport
) {

  Viewport.initScroll(false, $('section.home'));

  $scope.view = function (type) {
    Analytics.track('home page - view section click', {section: type});
    $location.path('/' + type);
  };

}]);
