app.controller('SignUpWholesalerController', ['$scope', 'Go', 'SignUp', 'Meta', 'Analytics', 'ASKKODIAK_APP_URL', 'Viewport', function (
  $scope,
  Go,
  SignUp,
  Meta,
  Analytics,
  ASKKODIAK_APP_URL,
  Viewport
) {

  Meta.setTitle('Sign Up for AskKodiak');

  $scope.wholesaler = {
    wholesaler: true
  };

  $scope.appURL = ASKKODIAK_APP_URL;
  $scope.loading = true;

  $scope.invitation = null;

  // look for an invitation code in the route parameters,
  // and if a valid one is there, get the data associated
  SignUp.checkForInvitation().then(function (invitation) {
    $scope.invitation = invitation;
    $scope.loading = false;
  }, function (error) {
    $scope.error = error;
    $scope.loading = false;
  });

  Viewport.initScroll(false, $('body'));

  $('body').addClass('full-page');

  $scope.$on('$destroy', function () {
    $('body').removeClass('full-page');
  });

  $scope.back = Go.back;

  $scope.promptForInviteCode = SignUp.promptForInviteCode;

  $scope.submit = function () {
    $scope.settingUp = true;

    SignUp.agency($scope.wholesaler, $scope.invitation).then(function (registrationResults) {
      if (registrationResults.status === 'registered') {
        Analytics.track('wholesaler sign up success', {agent: $scope.wholesaler.email});
        $scope.success = true;
      }
      if (registrationResults.status === 'pending') {
        Analytics.track('wholesaler sign up deferred', {agent: $scope.wholesaler.email});
        $scope.joinGroup = true;
      }

    }, function (error) {
      Analytics.track('wholesaler sign up failure', {agent: $scope.wholesaler.email, error: error});
      $scope.error = error;
      $scope.settingUp = false;
    });
  };

}]);
