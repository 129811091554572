app.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {

  $locationProvider.hashPrefix('');

  $routeProvider.
    when('/home', {
      // templateUrl: 'views/home.html',
      // controller: 'HomeController',
      // caseInsensitiveMatch: true
      resolveRedirectTo: ['$window', function ($window) {
        $window.location.href = 'https://www.ivans.com/for-carriers/products/ask-kodiak/';
      }]
    }).
    when('/carriers', {
      // templateUrl: 'views/carriers.html',
      // controller: 'CarriersController',
      // caseInsensitiveMatch: true
      resolveRedirectTo: ['$window', function ($window) {
        $window.location.href = 'https://www.ivans.com/for-carriers/products/ask-kodiak/';
      }]
    }).
    when('/agents', {
      // templateUrl: 'views/agents.html',
      // controller: 'AgentsController',
      // caseInsensitiveMatch: true
      resolveRedirectTo: ['$window', function ($window) {
        $window.location.href = 'https://www.ivans.com/for-agents/products/ask-kodiak/';
      }]
    }).
    when('/wholesalers', {
      // templateUrl: 'views/wholesalers.html',
      // controller: 'WholesalersController',
      // caseInsensitiveMatch: true
      resolveRedirectTo: ['$window', function ($window) {
        $window.location.href = 'https://www.ivans.com/for-mgas/products/ask-kodiak/';
      }]
    }).
    when('/carrier-faq', {
      templateUrl: 'views/carrier-faq.html',
      controller: 'CarrierFAQController',
      caseInsensitiveMatch: true
    }).
    when('/wholesaler-faq', {
      templateUrl: 'views/wholesaler-faq.html',
      controller: 'WholesalerFAQController',
      caseInsensitiveMatch: true
    }).
    when('/sign-up', {
      templateUrl: 'views/sign-up.html',
      controller: 'SignUpController',
      caseInsensitiveMatch: true
    }).
    when('/sign-up/agency', {
      templateUrl: 'views/sign-up/agency.html',
      controller: 'SignUpAgencyController',
      caseInsensitiveMatch: true
    }).
    when('/sign-up/carrier-trial', {
      templateUrl: 'views/sign-up/carrier.html',
      controller: 'SignUpCarrierController',
      caseInsensitiveMatch: true
    }).
    when('/sign-up/wholesaler', {
      templateUrl: 'views/sign-up/wholesaler.html',
      controller: 'SignUpWholesalerController',
      caseInsensitiveMatch: true
    }).
    when('/privacy', {
      templateUrl: 'views/privacy.html',
      controller: 'LegalController',
      caseInsensitiveMatch: true
    }).
    when('/terms-of-use', {
      templateUrl: 'views/terms-of-use.html',
      controller: 'LegalController',
      caseInsensitiveMatch: true
    }).
    when('/video/:mediaId', {
      templateUrl: 'views/video.html',
      controller: 'VideoController'
    }).
    otherwise({
      redirectTo: '/home'
    });
}]);
