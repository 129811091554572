app.factory('Viewport', ['$rootScope', function ($rootScope) {

  var scrolled = false,
      transitionElement = null,
      /* eslint-disable no-unused-vars */
      waypoint;
      /* eslint-enable no-unused-vars */

  function setWaypoint() {
    waypoint = new Waypoint({
      element: transitionElement,
      handler: function (direction) {
        if (direction === 'up') {
          scrolled = false;
        } else if (direction === 'down') {
          scrolled = true;
        }
        if (!$rootScope.$$phase) {
          $rootScope.$apply();
        }
      }
    });

  }

  return {
    getScrolled: function () {
      return scrolled;
    },
    setScrolled: function (isScrolled) {
      scrolled = isScrolled;
    },
    initScroll: function (isScrolled, element) {
      waypoint = null;
      scrolled = isScrolled;
      if (element) {
        transitionElement = element;
        setWaypoint();
      }
    }
  };

}]);
