app.factory('Analytics', ['KEEN_PARAMS', 'Version', '$location', function (
  KEEN_PARAMS,
  Version,
  $location) {

  var params = $location.search(),
      campaign = params.c || null,
      client = new Keen({
        projectId: KEEN_PARAMS.projectId,     // String (required always)
        writeKey: KEEN_PARAMS.writeKey       // String (required for sending data)
      });

  function extend(eventData) {
    eventData.appURL = $location.absUrl();
    eventData.version = Version.get();
    eventData.campaign = campaign;

    // The value of document.referrer an empty string if the user navigated to the page directly
    // (not through a link, but, for example, via a bookmark).
    eventData.referrer = document.referrer;

    //Keen IO Enhanced Data
    eventData.ip_address = '${keen.ip}';
    eventData.user_agent = '${keen.user_agent}';
    eventData.keen = {
      'addons': [
        {
          'name': 'keen:ip_to_geo',
          'input': {
            'ip': 'ip_address'
          },
          'output': 'ip_geo_info'
        },
        {
          'name': 'keen:ua_parser',
          'input': {
            'ua_string': 'user_agent'
          },
          'output': 'parsed_user_agent'
        }
      ]
    };

    return eventData;
  }

  return {
    track: function (collectionName, eventData) {
      var extendedData;

      //pass an empty event data object to keen if none made available
      if (typeof eventData === 'undefined') {
        eventData = {};
      }

      extendedData = extend(eventData);

      return client.recordEvent(collectionName, extendedData, function (err, res) {
        if (err) {
          /* eslint-disable no-console */
          console.log(err);
          /* eslint-enable no-console */
        }
      });

    } //track method

  }; //return object

}]); //keen Factory
