app.controller('SignUpCarrierController', ['$scope', '$location', '$window', '$log', 'Go', 'IP', 'Meta', 'Firebase', 'Mail', 'Notify', 'Analytics', 'ASKKODIAK_APP_URL', 'CARRIER_AGREEMENT', 'Viewport', function (
  $scope,
  $location,
  $window,
  $log,
  Go,
  IP,
  Meta,
  Firebase,
  Mail,
  Notify,
  Analytics,
  ASKKODIAK_APP_URL,
  CARRIER_AGREEMENT,
  Viewport
) {

  var authObj = Firebase.getAuth(),
      ipGeo = {},
      position = {};

  $scope.agreement = CARRIER_AGREEMENT;
  $scope.carrier = {};
  $scope.appURL = ASKKODIAK_APP_URL;

  Meta.setTitle('List a Product on AskKodiak');

  Viewport.initScroll(false, $('body'));

  $('body').addClass('full-page');

  $scope.$on('$destroy', function () {
    $('body').removeClass('full-page');
  });

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function success(pos) {
      position = {
        coords: {
          accuracy: pos.coords.accuracy,
          lat: pos.coords.latitude,
          long: pos.coords.longitude
        },
        website: $location.absUrl()
      };
    },
    function error(err) {
      position = err.message;
    });
  }

  IP.lookup().then(function (response) {
    ipGeo = response.data;
  }, function (error) {
    $log.error(error);
    ipGeo = error.statusText;
  });

  function sayThanks() {
    var message = {
      'subject': 'Thanks For Signing Up to Ask Kodiak',
      'to': [{
        'email': $scope.carrier.email,
        'name': $scope.carrier.firstName + ' ' + $scope.carrier.lastName,
        'type': 'to'
      }],
      'merge_vars': [{
        'rcpt': $scope.carrier.email,
        'vars': [
          {'name': 'HEADLINE', 'content': 'Welcome to Ask app. We\'re glad to have you!'},
          {'name': 'BODYCOPY', 'content': '<p>Our team is working behind-the-scenes now to get ' +
                                          $scope.carrier.companyName +
                                          ' setup.</p><p>We\'ll be in touch once it\'s all ready to go to get you started. ' +
                                          '<strong>It shouldn\'t take more than a few business hours.</strong></p>' +
                                          '<p>If you need anything in the meantime, or if it\'s taking too long, you can ' +
                                          'reply to this email to reach us.</p><p><strong>We\'ll be in touch soon!</strong></p>'
          }
        ]
      }]
    };
    Mail.send(message);
  }

  function enterWaitingRoom() {
    var requestor;

    requestor = {
      carrier: {
        name: $scope.carrier.companyName,
        website: $scope.carrier.website
      },
      user: {
        firstName: $scope.carrier.firstName,
        lastName: $scope.carrier.lastName,
        email: $scope.carrier.email,
        phone: $scope.carrier.phone
      },
      agreement: {
        version: $scope.agreement,
        at: Date.now(),
        from: {
          userAgent: $window.navigator.userAgent,
          ip: ipGeo,
          geo: position
        }
      }
    };

    return Firebase.push(requestor, 'landing-page/carrier-waiting-room');
  }

  $scope.back = Go.back;

  $scope.submit = function () {
    $scope.settingUp = true;

    authObj.$signInAnonymously().then(function (authData) {
      enterWaitingRoom();
      sayThanks();
      Notify.post({
        text: $scope.carrier.companyName + ' has entered the waiting room. Request to sign-up made by ' + $scope.carrier.email,
        username: 'New Carrier Request',
        icon_url: 'https://superkickventures.com/assets/etc/slackbots/megaphone.jpg'
      });
      Analytics.track('carrier sign up request', {
        carrier: $scope.carrier,
        agreement: $scope.agreement
      });
      $scope.success = true;
    }).catch(function (error) {
      Analytics.track('carrier sign up failure', {
        carrier: $scope.carrier,
        agreement: $scope.agreement,
        error: error
      });
      $scope.message = {
        text: error.message,
        class: 'danger'
      };
      $scope.settingUp = false;
    });
  };

  $scope.skippedProtocol = function () {
    var formField = $scope.carrierInquiry.website;

    if (formField.$invalid && formField.$touched) {
      if (formField.$viewValue) {
        if (formField.$viewValue.substring(0, 3) !== 'http') {
          return true;
        }
      }
    }
    return false;
  };

  $scope.showAgreement = function () {
    Analytics.track('carrier agreement viewed', {
      carrier: $scope.carrier,
      agreement: $scope.agreement
    });
  };

}]);
