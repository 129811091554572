app.controller('InviteCodeModalController', ['$scope', '$uibModalInstance', '$location', '$log', 'InvitationsData', 'Analytics', function (
  $scope,
  $uibModalInstance,
  $location,
  $log,
  InvitationsData,
  Analytics
) {

  Analytics.track('enter invite code modal opened');

  $scope.ok = function () {
    $scope.submitting = true;
    InvitationsData.get($scope.inviteCode).then(function (code) {
      var path = $location.path(); // get current path
      $location.path(path).search({inviteCode: $scope.inviteCode}); // add url parameter
      $scope.submitting = false;
      $uibModalInstance.close(); // close the modal
    }, function (error) {
      $scope.submitting = false;
      $scope.error = error;
      $log.error(error);
    });
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

}]);
