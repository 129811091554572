app.controller('MenuModalController', ['$scope', '$uibModalInstance', 'askkodiakAppURL', function (
  $scope,
  $uibModalInstance,
  askkodiakAppURL
) {

  $scope.askkodiakAppURL = askkodiakAppURL;

  $scope.ok = function (action) {
    $uibModalInstance.close(action);
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

}]);
