app.controller('WholesalerFAQController', ['$scope', 'Meta', 'Viewport', function (
  $scope,
  Meta,
  Viewport
) {

  Meta.setTitle('Wholesaler FAQ');

  Viewport.initScroll(false, $('#wholesaler-faq'));

  $scope.contact = {
    type: 'Wholesaler FAQ',
    options: {
      createLead: true
    }
  };

}]);
