app.constant('ASKKODIAK_APP_URL', 'https://app.askkodiak.com/')
  .constant('FIREBASE_CONFIG', {
    apiKey: 'AIzaSyCyM7e-RTAVyVJh8Bh8KMOOWOTgMTnYQPY',
    databaseURL: 'https://kodiak-app.firebaseio.com'
  })
  .constant('KEEN_PARAMS', {
    projectId: '5576f08c46f9a76de10be663',
    writeKey: 'd551324ca132666867eaf732ba12b0d438b2b2606ad6cf2f712b6758f2dc3aa98f0a8f81ae6692df84c6b0410787688f51a497b112ef6a96e7aeb7cb989e953d199610c7e25c0f5240b5b941418d16fbf5e7acabb1f45bee361665dedbb86d4ac5fbbcb18a9e7f8b27d97f00666a240a',
    protocol: 'https',
    host: 'api.keen.io/3.0',
    requestType: 'jsonp'
  })
  .constant('MAIL', {
    from: {
      address: 'help@askkodiak.com',
      name: 'Ask Kodiak'
    }
  })
  .constant('CARRIER_AGREEMENT', {
    url: 'https://askkodiakfilestorage.s3.amazonaws.com/agreement/customer-agreement-2017-01-23.pdf',
    revised: '2017-01-23'
  })
  .constant('ADDRESSES', {
    mailing: {
      title: 'Ask Kodiak (Superkick Ventures, LLC)',
      street: 'P.O. Box 45',
      city: 'Winchester',
      state: 'MA',
      zip: '01890'
    },
    business: {
      title: 'Ask Kodiak',
      street: '8 Winchester Place, #201',
      city: 'Winchester',
      state: 'MA',
      zip: '01890'
    }
  })
  .constant('CHANNEL', '#web-contact')
  .constant('VERSION', {
    name: 'ask-kodiak-landing-page',
    version: '5.3.1'
  });
