app.factory('Firebase', ['$firebaseObject', '$firebaseArray', '$firebaseAuth', function (
  $firebaseObject,
  $firebaseArray,
  $firebaseAuth) {

  return {
    obj: function (path) {
      var ref = firebase.database().ref(path);
      return $firebaseObject(ref);
    },

    array: function (path) {
      var ref = firebase.database().ref(path);
      return $firebaseArray(ref);
    },

    ref: function (path) {
      return firebase.database().ref(path);
    },

    fromRef: function (ref) {
      return $firebaseObject(ref);
    },

    push: function (object, path) {
      var refData = firebase.database().ref(path);
      return refData.push(object);
    },

    set: function (value, path, callback) {
      var refData = firebase.database().ref(path);
      return refData.set(value, callback);
    },

    remove: function (path) {
      var refData = firebase.database().ref(path);
      return refData.remove();
    },

    getAuth: function () {
      return $firebaseAuth();
    }
  };
}]);
