app.factory('SignUp', ['$log', '$q', '$location', '$rootScope', '$uibModal', 'MD5', 'Firebase', 'Tasks', 'DomainReservationsData', 'InvitationsData', function (
  $log,
  $q,
  $location,
  $rootScope,
  $uibModal,
  MD5,
  Firebase,
  Tasks,
  DomainReservationsData,
  InvitationsData
) {

  // clearTLD manages the process of clearing a TLD
  // returns a promise that resolves to...
  //
  // an object with properties:
  //
  // tld
  //    the TLD checked
  // hash
  //    the MD5 hash of the tld. because characters in
  //    a domain name can not be used as firebase keys, the domain is hashed
  // proceed
  //    should registration continue?
  //    false if the TLD is reserved AND the user chooses
  //          to go through the company admin for signup.
  //          otherwise true.
  // restricted
  //    was this tld found on the restricted list?
  // reservation
  //    a firebase object representing the reservation
  //    if there is one.
  function clearTLD(tld) {
    //init
    var results = {
      tld: tld,
      hash: MD5.hash(tld),
      proceed: true,
      restricted: null,
      reservation: null
    };

    $log.debug('clearing tld: ' + tld);
    // checks if the tld is a member of a list of restricted domains.
    // a restricted domain is a domain for which a reservation can not
    // be created, for example 'yahoo.com'.
    function isRestricted() {
      var i, //loop counter
          restrictedDomains = ['aol.com', 'att.net', 'comcast.net', 'facebook.com', 'gmail.com', 'gmx.com', 'googlemail.com', 'google.com', 'hotmail.com', 'hotmail.co.uk', 'mac.com', 'me.com', 'mail.com', 'msn.com', 'live.com', 'sbcglobal.net', 'verizon.net', 'yahoo.com', 'yahoo.co.uk', 'email.com', 'games.com', 'gmx.net', 'hush.com', 'hushmail.com', 'icloud.com', 'inbox.com', 'lavabit.com', 'love.com', 'outlook.com', 'pobox.com', 'rocketmail.com', 'safe-mail.net', 'wow.com', 'ygm.com', 'ymail.com', 'zoho.com', 'fastmail.fm', 'yandex.com', 'bellsouth.net', 'charter.net', 'comcast.net', 'cox.net', 'earthlink.net', 'juno.com', 'btinternet.com', 'virginmedia.com', 'blueyonder.co.uk', 'freeserve.co.uk', 'live.co.uk', 'ntlworld.com', 'o2.co.uk', 'orange.net', 'sky.com', 'talktalk.co.uk', 'tiscali.co.uk', 'virgin.net', 'wanadoo.co.uk', 'bt.com', 'sina.com', 'qq.com', 'naver.com', 'hanmail.net', 'daum.net', 'nate.com', 'yahoo.co.jp', 'yahoo.co.kr', 'yahoo.co.id', 'yahoo.co.in', 'yahoo.com.sg', 'yahoo.com.ph', 'hotmail.fr', 'live.fr', 'laposte.net', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'gmx.fr', 'sfr.fr', 'neuf.fr', 'free.fr', 'gmx.de', 'hotmail.de', 'live.de', 'online.de', 't-online.de', 'web.de', 'yahoo.de', 'mail.ru', 'rambler.ru', 'yandex.ru', 'ya.ru', 'list.ru', 'hotmail.be', 'live.be', 'skynet.be', 'voo.be', 'tvcablenet.be', 'telenet.be', 'hotmail.com.ar', 'live.com.ar', 'yahoo.com.ar', 'fibertel.com.ar', 'speedy.com.ar', 'arnet.com.ar', 'hotmail.com', 'gmail.com', 'yahoo.com.mx', 'live.com.mx', 'yahoo.com', 'hotmail.es', 'live.com', 'hotmail.com.mx', 'prodigy.net.mx', 'msn.com']; //'https://github.com/mailcheck/mailcheck/wiki/List-of-Popular-Domains'

      for (i = 0; i < restrictedDomains.length; i++) {
        if (restrictedDomains[i] === tld) {
          $log.debug(tld + ' is a restricted domain for which a reservation can not be created.');
          return true;
        }
      }

      $log.debug(tld + ' is not on the list of restricted domains.');
      return false;
    }
    // presents a modal dialog to the user that others on their team may
    // already be on the platform and asks them what they want to do next.
    // the user can choose to go-ahead anyway and create their own new group
    // or instead ask to join the existing team.
    function prompt(reservation) {
      $log.debug(tld + ' is already reserved by ' + reservation.name);
      return $q(function (resolve, reject) {
        var promptModal = $uibModal.open({
          templateUrl: 'views/modals/reserved-domain.html',
          controller: 'ReservedDomainModalController',
          backdrop: 'static',
          size: 'md',
          resolve: {
            reservation: function () {
              return reservation;
            }
          }
        });

        promptModal.result.then(function (response) {
          // resolves to a string, "add" or "ignore"
          //
          // add sends a notification to admins and halts sign-up.
          // ignore creates a new agency.
          //
          resolve(response);
        });
      });
    }
    // the promise returned by this function, which orchestrates
    // sequence of checks, lookups, and prompts
    return $q(function (resolve, reject) {
      // check if the tld is on the restricted list
      results.restricted = isRestricted();
      if (results.restricted === true) {
        // this is a restricted domain, so reservations can not be created
        // for it. There's nothing further to do now. Sign-up can proceed.
        $log.debug(tld + ' is a restricted domain and can not be reserved by anyone. sign-up should proceed');
        resolve(results);
      } else {
        // if we're this far, it's not a restricted domain. Check to see if
        // there's an existing reservation for this TLD
        DomainReservationsData.forHash(results.hash).then(function (reservation) {
          // this is a reserved domain if there's alread a GID at this hash.
          // otherwise, it's not in use. all clear.
          if (!reservation.gid) {
            $log.debug('there is no reservation for ' + tld + '. sign up can proceed without prompt.');
            resolve(results);
          } else {
            // this domain is reserved. prompt the user what they want
            // to do next...
            prompt(reservation).then(function (response) {
              results.reservation = reservation;
              // "add" or "ignore"
              if (response === 'add') {
                results.proceed = false;
                resolve(results);
              }
              resolve(results);
            });
          }
        }, function (error) {
          reject(error);
        });
      }
    });
  }

  return {
    agency: function (registrationData, invitation) {

      var authObj = Firebase.getAuth(),
          userDomain = registrationData.email.split('@')[1],
          uid;

      if (typeof invitation === 'undefined') {
        invitation = {};
      }

      function createProvisioningTask() {
        var agency,
            user,
            wholesaler;

        agency = {
          name: registrationData.agency,
          phone: registrationData.phone || null,
          website: registrationData.website || null,
          location: registrationData.location || null
        };
        user = {
          firstName: registrationData.firstName,
          lastName: registrationData.lastName,
          email: registrationData.email
        };
        wholesaler = registrationData.wholesaler || false;

        return Tasks.provisionAgency(uid, agency, user, wholesaler, invitation);
      }

      function createAddUserRequestTask(reservation) {
        var req,
            res;

        req = {
          firstName: registrationData.firstName,
          lastName: registrationData.lastName,
          email: registrationData.email
        };

        res = {
          domain: reservation.domain,
          gid: reservation.gid,
          type: reservation.type,
          name: reservation.name
        };
        return Tasks.addUserRequest(req, res);
      }

      return $q(function (resolve, reject) {
        // first check to see if the tld is either reserved or in use already. If it's in use,
        // that's an indicator that this user belongs to an agency that's already on the platform.
        // if it's reserved, its a domain like gmail.com for which a reservation can not be created.
        clearTLD(userDomain).then(function (tldResults) {
          if (tldResults.proceed === true) {
            authObj.$createUserWithEmailAndPassword(registrationData.email, registrationData.password).then(function (userData) {
              var user = userData.user || {};
              uid = user.uid;
              createProvisioningTask().then(function () {
                resolve({
                  status: 'registered'
                });
              }, function (error) {
                reject(error);
              });
            }).catch(function (error) {
              reject(error);
            });
          } else {
            createAddUserRequestTask(tldResults.reservation).then(function () {
              resolve({
                status: 'pending'
              });
            }, function (error) {
              reject(error);
            });
          }
        }, function (error) {
          reject(error);
        });
      });
    },
    promptForInviteCode: function () {
      // creates a modal which collects an invitation code from the user
      // and gets it into root scope by adding it to the URL as a
      // parameter called inviteCode
      var inviteModal = $uibModal.open({
        templateUrl: 'views/modals/invite-code.html',
        controller: 'InviteCodeModalController',
        backdrop: 'static',
        size: 'md'
      });

      inviteModal.result.then(function () {
        $log.debug('Modal completed at: ' + new Date());
      }, function () {
        $log.debug('Modal dismissed at: ' + new Date());
      });
    },
    checkForInvitation: function () {
      // checks to see if an invitation code exists in
      // either root scope or in the URL parameters
      //
      // returns either the invitation as an object
      // or null if none exists for a given inviteCode
      return $q(function (resolve, reject) {

        var inviteCode = $rootScope.inviteCode,
            params = $location.search();

        // if inviteCode is not in rootScope, look for
        // it in query parameters
        if (!inviteCode) {
          inviteCode = params.inviteCode;
        }

        // if it's there, try and look up the invitation
        if (inviteCode) {
          InvitationsData.get(inviteCode).then(function (invitation) {
            resolve({
              code: inviteCode,
              id: invitation.id,
              logo: invitation.logo,
              name: invitation.name
            });
          }, function (error) {
            reject(error);
          });
        } else {
          //no invite
          resolve();
        }

      });
    }
  };

}]);
