app.controller('CarrierFAQController', ['Meta', 'Viewport', function (
  Meta,
  Viewport
) {

  Meta.setTitle('Carrier FAQ');

  Viewport.initScroll(false, $('#carrier-faq'));

}]);
