app.factory('ContactData', ['CHANNEL', 'Firebase', 'Notify', function (
  CHANNEL,
  Firebase,
  Notify
) {

  var authObj = Firebase.getAuth();

  function saveContact(contact) {

    var notificationTitle = 'Website Contact Form Submission';

    if (contact.type) {
      notificationTitle = notificationTitle + ' (' + contact.type + ')';
    }

    Firebase.push(contact, '/landing-page/contact-us');

    Notify.post({
      username: notificationTitle,
      text: 'from : ' + contact.name + ' (' +  contact.email + ') \n' +
            'phone: ' + contact.phone + '\n' +
            'message: ' + contact.message,
      channel: CHANNEL,
      icon_url: 'https://superkickventures.com/assets/etc/slackbots/kodiak-web.png'
    });
  }

  return {
    push: function (contact) {
      authObj.$signInAnonymously().then(function (authData) {
        saveContact(contact);
      });
    }
  };

}]);
