app.controller('AgentsController', ['$scope', '$window', 'Meta', 'Analytics', 'Viewport', function ($scope, $window, Meta, Analytics, Viewport
) {

  Meta.setTitle('Ask Kodiak for Agents');

  Viewport.initScroll(false, $('section.agents'));

  /*
  $('body').addClass('purple');

  $scope.$on('$destroy', function () {
    $('body').removeClass('purple');
  })
  */

  /* ---- WISTIA STUFF ------ */
  $scope.play = function (video) {
    Analytics.track('video-play', {
      videoName: video._mediaData.name,
      videoId: video._mediaData.hashedId,
      videoPlayerType: video.playerType
    });
  };
  // Get a handle on embedded wistia videos so that we can track that they're playing.
  $window._wq = $window._wq || [];

  $window._wq.push({ '_all': function (video) {
    video.bind('play', function () {
      $scope.play(this);
    });
  }});
  /* ---- END WISTIA STUFF ------ */

}]);
