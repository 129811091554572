app.controller('MenuController', ['$scope', '$uibModal', '$location', 'Viewport', 'Analytics', 'ASKKODIAK_APP_URL', function (
  $scope,
  $uibModal,
  $location,
  Viewport,
  Analytics,
  ASKKODIAK_APP_URL
) {

  $scope.askkodiakAppURL = ASKKODIAK_APP_URL;
  $scope.inverse = true;

  $scope.isActive = function (viewLocation) {
    var appArea = $location.path().split('/')[1];
    return appArea.indexOf(viewLocation) > -1;
    //return viewLocation === appArea;
  };

  $scope.scrolled = function () {
    return Viewport.getScrolled();
  };

  $scope.navTo = function (action) {
    Analytics.track('menu action', {action: action});
  };

  $scope.open = function () {

    var menuModal;

    Analytics.track('menu modal displayed');

    menuModal = $uibModal.open({
      templateUrl: 'views/menu-modal.html',
      controller: 'MenuModalController',
      size: 'lg',
      openedClass: 'fs-modal',
      backdrop: 'static',
      resolve: {
        askkodiakAppURL: function () {
          return $scope.askkodiakAppURL;
        }
      }
    });

    menuModal.result.then(function (action) {
      Analytics.track('menu modal resolved', {action: action});
    }, function () {
      Analytics.track('menu modal cancelled');
    });
  };

}]);
