app.controller('SignUpController', ['$scope', 'Meta', 'Viewport', function (
  $scope,
  Meta,
  Viewport
) {

  Meta.setTitle('Sign Up for AskKodiak');

  Viewport.initScroll(false, $('body'));

  $('body').addClass('full-page');

  $scope.$on('$destroy', function () {
    $('body').removeClass('full-page');
  });

}]);
