app.factory('DomainReservationsData', ['Firebase', '$q', function (Firebase, $q) {

  var authObj = Firebase.getAuth();

  return {
    forHash: function (hash) {
      return $q(function (resolve, reject) {
        authObj.$signInAnonymously().then(function (authData) {
          Firebase.obj('/domain-reservations/' + hash).$loaded().then(function (reservation) {
            resolve(reservation);
          }, function (error) {
            reject(error);
          });
        });
      });
    }
  };

}]);
