app.factory('Tasks', ['$q', '$log', 'Firebase', function (
  $q,
  $log,
  Firebase
) {

  function addToQueue(task) {
    return $q(function (resolve, reject) {
      var authObj = Firebase.getAuth(),
          authData = authObj.$getAuth();

      function pushTask(uid) {
        var uidProperty = '_uid';
        task[uidProperty] = uid;
        return Firebase.push(task, '/queue/tasks');
      }

      // if auth data user is authenticated to firebase
      if (authData) {
        $log.info('pushing task for existing session ' + authData.uid);
        resolve(pushTask(authData.uid));
      } else {
        $log.info('creating new session before pushing task');
        // user will at least need to auth anonymously before creating a task
        authObj.$signInAnonymously().then(function (authData) {
          $log.info('new session created. pushing task for ' + authData.uid);
          resolve(pushTask(authData.uid));
        }, function (error) {
          reject(error);
        });
      }
    });
  }

  return {

    createLead: function (lead) {
      var task = {
        _state: 'create_lead_start',
        lead: lead
      };
      return addToQueue(task);
    },

    mailer: function (message) {
      var task = {
        _state: 'mailer_start',
        message: message
      };
      return addToQueue(task);
    },

    notify: function (message) {
      var task = {
        _state: 'notifier_start',
        message: message
      };
      return addToQueue(task);
    },

    provisionAgency: function (uid, agency, user, wholesaler, invitation) {
      var task = {
        _state: 'provision_agency_start',
        uid: uid,
        agency: agency,
        user: user,
        wholesaler: wholesaler,
        invitation: invitation
      };
      return addToQueue(task);
    },

    addUserRequest: function (requestor, reservation) {
      var task = {
        _state: 'add_user_request_start',
        requestor: requestor,
        reservation: reservation
      };
      return addToQueue(task);
    }

  };

}]);
